export const WELLNESS_OPIONS = [
    "Acupuncturist", "Chiropractor", "Esthetician", "Medical Doctor/D.O.", "Naturopath", "Nurse/Nurse Practitioner", "Nutritionist", "Health Coach", "Fitness Instructor",
    "Mental Health Therapist", "Massage Therapist", "Pain Specialist", "Pharmacist", "Physical Therapist", "Ph.D.", "Wellness Influencer", "Other"
];
export const SOCIAL_PLATFORMS = [
    "Instagram", "Facebook", "LinkedIn", "YouTube", "Google", "Word of mouth", "Wellness provider", "Event (please specify below)", "Other (please specify below)"
];
export const PERSONALIZED_ICONS = [
    { icon: "non_gmo_icon.png", text: "Non GMO" },
    { icon: "daily_free.png", text: "Dairy Free" },
    { icon: "soy_free.png", text: "Soy Free" },
    { icon: "gluten_free_icon.png", text: "Gluten Free" },
    { icon: "nut_free_icon.png", text: "Nut Free" }
]